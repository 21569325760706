import React, {useEffect, useState, useRef} from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import axios from "axios";
import {Form, Table, Button, Col, Row} from "react-bootstrap";
import alertify from "alertifyjs";
import {API_URL} from "../constants.js";
import {AutoSuggest} from 'react-autosuggestions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {datetime_local} from '../Common/DateFormat';


// bs ye setting krni thi. k import ko optiize na kr de. kiu k datatable me issue ajata ota hauski import ko remove kr deta ha ptimize me. lekin wo use honi hoti ha.
//boss ye jo ap ny component bnwaya tha dateformate ka . ye ap ny cameCase ni balky sanke_case krwaya tha. confusion again.mel case kr den acha ni lag rha me ne dekha. ok,
// blkay agr baqi componts ki trah first letter b capital krna ho to b thek ha. symmetey rhay gi.  minu pul jana.
//fil emN likhh do. mn wo note krna.

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SVG from "react-inlinesvg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function PatientEdit(props) {
  const {t} = useTranslation();
  const first_name = useRef();
  const sample_id = useRef();
  const last_name = useRef();
  const gender = useRef();
  const dob = useRef();
  const email = useRef();
  const phone_no = useRef();
  const address = useRef();
  const registered_at = useRef();
  const collection_datetime = useRef();
  const paid_amount = useRef();
  const reference_id = useRef();
  const collection_center_id = useRef();
  const status = useRef();
  const due_amount = useRef();
  const postal_code = useRef();
  const passport_number = useRef();
  const date_arrived = useRef();
  const date_left = useRef();
  const nhs_number = useRef();
  const surgery_name = useRef();
  const gp_surgery_email = useRef();
  const gp_name = useRef();
  const ethnicity = useRef();
  const vaccination_status = useRef();
  const phe_report_date = useRef();
  const title = useRef();
  const royal_mail_tracking_no = useRef();

  const [reference_dropdown, setReferenceDropdown] = useState(0);
  const [collectioncenter_dropdown, setCollectionCenterDropdown] = useState(0);
  const [gender_dropdown, setGenderDropdown] = useState(0);
  const [collection_center_datetime, setCollectionCenterDateTime] = useState('');
  const [registered_at_datetime, setRegisteredAtDateTime] = useState('');
  const [patientTitle, setPatientTitle] = useState();

  // ref mean k useRef use hua ha. agr useRef ni kiya to ref k equal to rakhnay se error e aye ga. ref k equal wahi rekh sktay  jis ko use ref kiya o
  const [test, setTest] = useState();
  const [due, setDue] = useState(0);
  const [references, setReferences] = useState([]); // array means multiple
  const [collectionCenters, setCollectionCenters] = useState([]); // jahan b array ho hmesha uski convention s k sath end honi chahye.
  const [patient, setPatient] = useState({});
  const [net_amount, setNetAmount] = useState(0);

  const [disabled, setDisabled] = useState(0);
  const [iconDisabled, setIconDisabled] = useState(1);

  const [options, setOptions] = useState(["HBsAg (screening)"]);
  const [letters, setLetters] = useState('');
  const [tests, setTests] = useState([]);

  const id = props.match.params.id;

  useEffect(() => {
    axios.get(API_URL + 'patient/' + id).then((response) => {

      // response.data react ki traf se ha. is k baad hmen response ko dekhna ha.
      // mtlab respnse.data me data wo ni ha jo backend se aa rha ha. wo data ala ha.
      //response.data ye default a. lekin hum b jo sb bhej rhay uska naam b data ha. is liye confuse ho rha. to data ab 2 dfa aye ga.ok

      if (response.data.status) {
        // console.log(response.data.data.patient);
        setPatient(response.data.data.patient);
        setNetAmount(response.data.data.patient.net_amount);
        setReferences(response.data.data.references);
        setCollectionCenters(response.data.data.collectioncenters);

        //Set values for dropdowns
        setReferenceDropdown(response.data.data.patient.reference_id);
        setGenderDropdown(response.data.data.patient.gender);
        setCollectionCenterDropdown(response.data.data.patient.collection_center_id);
        setRegisteredAtDateTime(datetime_local(response.data.data.patient.registered_at));
        setCollectionCenterDateTime(datetime_local(response.data.data.patient.collection_datetime));
        setPatientTitle(response.data.data.patient.title);


        var existingTests = response.data.data.patient.patient_tests;
        var existingTestsArray = [];

        existingTests.map((patient_test, index) => {
          let testObj = {
            id: patient_test.test_id,
            name: patient_test.test.name,
            amount: patient_test.net_amount,
            turn_around_time: patient_test.test.turn_around_time,
            sampletype: patient_test.test.sample_type.name
          }
          existingTestsArray.push(testObj);

        });
        setTests(existingTestsArray);

      } else {
        alertify.error(response.data.message);
        return null;
      }
    })

  }, []);

  useEffect(() => {
    calculate();
  }, [tests]);

  const calculate = () => {
    var net_amount = 0;
    tests.map((element, index) => {
      net_amount += parseFloat(element.amount);
    });
    setNetAmount(net_amount);
    setDue(net_amount - paid_amount.current.value);
  };

  const handlePayment = () => { // function name camel case e jese ye ha. laraavel ?? same camel caseok
    calculate();
  }

  const handleReferenceChange = (e) => {
    setReferenceDropdown(e.target.value);
  }

  const handleCollectionCenterChange = (e) => {
    setCollectionCenterDropdown(e.target.value);
  }

  const handleGenderChange = (e) => {
    setGenderDropdown(e.target.value);
  }

  const handlePatientTitleChange = (e) => {
    setPatientTitle(e.target.value);
  }

  const removeTest = (index) => {
    let arr = tests;
    arr = arr.filter((item) => item.name !== index);
    setTests(arr);
  }

  const handleAutoComplete = (letters) => {
    setLetters(letters);
    if (letters != '') {

      axios.post(API_URL + "search", {
        name: letters,
      }).then((response) => {
        // console.log(response.data);
        if (response.data.data.length > 0) {
          var names = [];
          response.data.data.map((item) => {
            names.push(item.name);
          });
          setOptions(names);

          var selectedIndex = response.data.data.findIndex(item => item.name === letters);
          if (selectedIndex != -1) {

            var selectedTest = {
              id: response.data.data[selectedIndex].id,
              name: response.data.data[selectedIndex].name,
              amount: response.data.data[selectedIndex].amount,
              turn_around_time: response.data.data[selectedIndex].turn_around_time,
              sampletype: response.data.data[selectedIndex].sample_type.name
            }
            setTests([...tests, selectedTest]);
            setLetters('');

          }
        }
      }).catch((error) => {
        console.log('Nothing found');
      });

    }
  }

  const editPatientHandler = (e) => {

    e.preventDefault();
    setDisabled(1);
    setIconDisabled(0);
    //Tests array
    var testlist = [];
    tests.map((element, index) => {
      let temp = {
        "test_id": element.id,
        "amount": element.amount
      }
      testlist.push(temp);
    });
    if (testlist.length == 0) {
      setDisabled(0);
      setIconDisabled(1);
      alertify.error("Please select atleast one test");
      return false;
    }

    axios.post(API_URL + "patient/update",
      {
        id: id,
        title: title.current.value,
        first_name: first_name.current.value,
        sample_id: sample_id.current.value,
        last_name: last_name.current.value,
        dob: dob.current.value,
        gender: gender.current.value,
        phone_no: phone_no.current.value,
        email: email.current.value,
        // address: address.current.value,
        // postal_code: postal_code.current.value,
        // passport_number: passport_number.current.value,
        royal_mail_tracking_no: royal_mail_tracking_no.current.value,
        date_arrived: date_arrived.current.value,
        date_left: date_left.current.value,
        surgery_name: surgery_name.current.value,
        gp_surgery_email: gp_surgery_email.current.value,
        gp_name: gp_name.current.value,
        nhs_number: nhs_number.current.value,
        ethnicity: ethnicity.current.value,
        vaccination_status: vaccination_status.current.value,
        phe_report_date: phe_report_date.current.value,
        registered_at: registered_at.current.value,
        collection_datetime: collection_datetime.current.value,
        collection_center_id: collection_center_id.current.value,
        reference_id: reference_id.current.value,
        paid_amount: paid_amount.current.value,
        tests: testlist
      }
    ).then((response) => {
      setDisabled(0);
      setIconDisabled(1);
      if (response.data.status == true) {

        alertify.success(response.data.message);
        props.history.push("/patient/list");
      } else if (response.data.status == false) {
        alertify.error(response.data.message);
        return null;
      }
    }).catch((error) => {
      setDisabled(0);
      setIconDisabled(1);
      if (error.message === 'Request failed with status code 401') {
        //props.logout();
      }
    });
  }

  return (

    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title={t("Add New Patient")}>
            <CardHeaderToolbar>
              <Link to="/patient/list" className="btn btn-primary btn-sm mx-3">
                {t("Patients List")}
              </Link>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <Form onSubmit={editPatientHandler}>
              <div className="mt-5">
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{t("Sample ID")}</Form.Label>
                    <Form.Control ref={sample_id} placeholder={t("Sample ID")}
                                  defaultValue={patient.sample_id}/>
                  </Form.Group>
                  <Form.Group as={Col} className={"patient_optional_fields"}>
                    <Form.Label>{t("Title")}</Form.Label>
                    <Form.Control as="select" ref={title} value={patientTitle}
                                  onChange={handlePatientTitleChange}>
                      <option value="">{t("Not Stated")}</option>
                      <option value="Mr.">{t("Mr.")}</option>
                      <option value="Mrs.">{t("Mrs.")}</option>
                      <option value="Miss.">{t("Miss.")}</option>
                      <option value="Ms.">{t("Ms.")}</option>
                      <option value="Dr.">{t("Dr.")}</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{t("First Name")}</Form.Label>
                    <Form.Control ref={first_name} placeholder={t("First Name")}
                                  defaultValue={patient.first_name}/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{t("Last Name")}</Form.Label>
                    <Form.Control ref={last_name} placeholder={t("Last Name")}
                                  defaultValue={patient.last_name}/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{t("Date Of Birth")}</Form.Label>
                    <Form.Control type={"date"} ref={dob} placeholder={t("Date Of Birth")}
                                  defaultValue={patient.dob}/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{t("Gender")}</Form.Label>
                    <Form.Control as="select" ref={gender} onChange={handleGenderChange}
                                  value={gender_dropdown}>
                      <option value="male">{t("Male")}</option>
                      <option value="female">{t("Female")}</option>
                      <option value="na">{t("Not Answered")}</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  {/*<Form.Group as={Col}>
                                        <Form.Label>{t("Address")}</Form.Label>
                                        <Form.Control ref={address} placeholder={t("Address")}
                                                      defaultValue={patient.address}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Post Code")}</Form.Label>
                                        <Form.Control ref={postal_code} placeholder={t("Post Code")}
                                                      defaultValue={patient.postal_code}/>*/}


                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{t("Email")}</Form.Label>
                    <Form.Control type={"email"} ref={email} placeholder={t("Email")}
                                  defaultValue={patient.email}/>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>{t("Phone No")}</Form.Label>
                    <Form.Control ref={phone_no} placeholder={t("Phone No")}
                                  defaultValue={patient.phone_no}/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{t("Royal Mail Tracking Number")}</Form.Label>
                    <Form.Control ref={royal_mail_tracking_no} placeholder={t("Royal Mail Tracking Number")}
                                  defaultValue={patient.royal_mail_tracking_no}/>
                  </Form.Group>
                </Form.Row>

                <Form.Row>

                  <Form.Group as={Col} className={"patient_optional_fields"}>
                    <Form.Label>{t("Date Arrived UK")}</Form.Label>
                    <Form.Control ref={date_arrived} type={'date'}
                                  placeholder={t("Date Arrived UK")}
                                  defaultValue={patient.date_arrived}/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} className={"patient_optional_fields"}>
                    <Form.Label>{t("Date Left")}</Form.Label>
                    <Form.Control ref={date_left} type={'date'} placeholder={t("Date Left")}
                                  defaultValue={patient.date_left}/>
                  </Form.Group>
                  {/*<Form.Group as={Col} className={"patient_optional_fields"}>
                                        <Form.Label>{t("NHS Number")}</Form.Label>
                                        <Form.Control ref={nhs_number} placeholder={t("NHS Number")}
                                                      defaultValue={patient.nhs_number}/>
                                    </Form.Group>*/}
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{t("Surgery Name")}</Form.Label>
                    <Form.Control ref={surgery_name} placeholder={t("Surgery Name")}
                                  defaultValue={patient.surgery_name}/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{t("GP/Surgery Email")}</Form.Label>
                    <Form.Control ref={gp_surgery_email} placeholder={t("GP/Surgery Email")}
                                  defaultValue={patient.gp_surgery_email}/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{t("GP Name")}</Form.Label>
                    <Form.Control ref={gp_name} placeholder={t("GP Name")}
                                  defaultValue={patient.gp_name}/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{t("NHS Number")}</Form.Label>
                    <Form.Control ref={nhs_number} placeholder={t("NHS Number")}
                                  defaultValue={patient.nhs_number}/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} className={"patient_optional_fields"}>
                    <Form.Label>{t("Ethnicity")}</Form.Label>
                    <Form.Control ref={ethnicity} placeholder={t("Ethnicity")}
                                  defaultValue={patient.ethnicity}/>
                  </Form.Group>
                  <Form.Group as={Col} className={"patient_optional_fields"}>
                    <Form.Label>{t("Vaccination Status")}</Form.Label>
                    <Form.Control ref={vaccination_status} placeholder={t("Vaccination Status")}
                                  defaultValue={patient.vaccination_status}/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} className={"patient_optional_fields"}>
                    <Form.Label>{t("Date to report to PHE")}</Form.Label>
                    <Form.Control ref={phe_report_date} type={'date'}
                                  placeholder={t("Date to report to PHE")}
                                  defaultValue={patient.phe_report_date}/>
                  </Form.Group>
                  <Form.Group as={Col}>
                  </Form.Group>
                </Form.Row>


                <Form.Row>

                  <Form.Group as={Col}>
                    <Form.Label>{t("Registration Date")}</Form.Label>
                    <Form.Control type={"datetime-local"} ref={registered_at}
                                  placeholder={t("Registration Date")}
                                  defaultValue={registered_at_datetime}/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{t("Collection Date")}</Form.Label>
                    <Form.Control type={"datetime-local"} ref={collection_datetime}
                                  placeholder={t("Collection Date")}
                                  defaultValue={collection_center_datetime}/>
                  </Form.Group>
                  <Form.Group as={Col} className={"patient_optional_fields"}>
                    <Form.Label>{t("Collection Center")}</Form.Label>
                    <Form.Control as="select" ref={collection_center_id}
                                  onChange={handleCollectionCenterChange}
                                  value={collectioncenter_dropdown}>
                      {collectionCenters.length > 0 ? (
                        collectionCenters.map((collectionCenter, index) => (
                          <option value={collectionCenter.id}
                                  key={index}>{collectionCenter.name}</option>
                        ))
                      ) : (
                        <option value={"0"}>{t("No Collection Center")}</option>
                      )}
                    </Form.Control>

                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} className={"patient_optional_fields"}>
                    <Form.Label>{t("References")}</Form.Label>
                    <Form.Control as="select" ref={reference_id} onChange={handleReferenceChange}
                                  value={reference_dropdown}>
                      {references.length > 0 ? (
                        references.map((reference, index) => (
                          <option value={reference.id} key={index}>{reference.name}</option>
                        ))
                      ) : (
                        <option value={"0"}>{t("No Reference")}</option>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              </div>
              <Row>
                <Col md={8}>
                  <AutoSuggest
                    name="Test"
                    handleChange={(e) => {
                      handleAutoComplete(e);
                    }}
                    options={options}
                    value={letters}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <Table striped bordered hover>
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("Test Name")}</th>
                      <th>{t("Sample")}</th>
                      <th>{t("Turnaround Time")}</th>
                      <th>{t("Amount")}</th>
                      <td>{t("Action")}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {tests.length > 0 ? (
                      tests.map((element, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{element.name}</td>
                          <td>{element.sampletype}</td>
                          <td>{element.turn_around_time}</td>
                          <td>{element.amount}</td>
                          <td>
                            <a
                              onClick={() => removeTest(element.name)}
                              className="btn btn-icon btn-light btn-hover-danger btn-sm">
													<span className="svg-icon svg-icon-md svg-icon-danger">
													  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
													</span>
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}

                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <Table striped bordered hover>
                    <thead>
                    <tr>
                      <th>{t("Net Amount")}</th>
                      <th>{net_amount}</th>
                    </tr>
                    <tr>
                      <th>{t("Paid")}</th>
                      <th>
                        <Form.Control ref={paid_amount} defaultValue={patient.paid_amount}
                                      placeholder={t("Customer Paid")}
                                      onKeyDown={handlePayment} onKeyUp={handlePayment}
                                      onKeyPress={handlePayment}/>
                      </th>
                    </tr>
                    <tr>
                      <th>{t("Due Amount")}</th>
                      <th>
                        <Form.Control ref={due_amount} value={due}
                                      defaultValue={patient.due_amount}
                                      placeholder={t("Due Amount")}
                                      onKeyDown={handlePayment} onKeyUp={handlePayment}
                                      onKeyPress={handlePayment}/>
                      </th>
                    </tr>
                    </thead>
                  </Table>
                </Col>
              </Row>

              <Button variant="primary" className="float-right" type="submit" disabled={disabled}>
                {t("Save")}
                <span
                  dangerouslySetInnerHTML={{__html: disabled ? `<i class='fas fa-spinner fa-spin'></i>` : ``}}/>
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}